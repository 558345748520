/* ********** Astrodivider ************/
.astrodivider {
    margin:64px auto;
    width:400px; 
    max-width: 100%;
    position:relative;
  }
  
  .astrodividermask { 
      overflow:hidden; height:20px; 
  }
  
  .astrodividermask:after { 
        content:''; 
        display:block; margin:-25px auto 0;
        width:100%; height:25px;  
          border-radius:125px / 12px;
         box-shadow:0 0 8px var(--color-primary);
  }
  .astrodivider span {
      width:50px; height:50px; 
      position:absolute; 
      bottom:100%; margin-bottom:-25px;
      left:50%; margin-left:-25px;
      border-radius:100%;
     box-shadow:0 2px 4px var(--color-primary);
    
  }
  .astrodivider i {
      position:absolute;
      top:4px; bottom:4px;
      left:4px; right:4px;
      border-radius:100%;
      border:1px dashed var(--color-secondary);
      text-align:center;
      line-height:40px;
      font-style:normal;
       color:var(--color-secondary);
  }
