footer {
    background-color: var(--color-primary);
    background-image: url("data:image/svg+xml,%3Csvg width='20' height='12' viewBox='0 0 20 12' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6 12c0-.622-.095-1.221-.27-1.785A5.982 5.982 0 0 0 10 12c1.67 0 3.182-.683 4.27-1.785A5.998 5.998 0 0 0 14 12h2a4 4 0 0 1 4-4V6c-1.67 0-3.182.683-4.27 1.785C15.905 7.22 16 6.622 16 6c0-.622-.095-1.221-.27-1.785A5.982 5.982 0 0 0 20 6V4a4 4 0 0 1-4-4h-2c0 .622.095 1.221.27 1.785A5.982 5.982 0 0 0 10 0C8.33 0 6.818.683 5.73 1.785 5.905 1.22 6 .622 6 0H4a4 4 0 0 1-4 4v2c1.67 0 3.182.683 4.27 1.785A5.998 5.998 0 0 1 4 6c0-.622.095-1.221.27-1.785A5.982 5.982 0 0 1 0 6v2a4 4 0 0 1 4 4h2zm-4 0a2 2 0 0 0-2-2v2h2zm16 0a2 2 0 0 1 2-2v2h-2zM0 2a2 2 0 0 0 2-2H0v2zm20 0a2 2 0 0 1-2-2h2v2zm-10 8a4 4 0 1 0 0-8 4 4 0 0 0 0 8zm0-2a2 2 0 1 0 0-4 2 2 0 0 0 0 4z' fill='%235c8588' fill-opacity='0.09' fill-rule='evenodd'/%3E%3C/svg%3E");
    padding: 3rem 0;
    text-align: center;
    font-size: 0.9rem;
    margin-top: 7rem;
}

a, a > span {
    position: relative;
    color: inherit;
    text-decoration: none;
    line-height: 24px;
  }
  a:before, a:after, a > span:before, a > span:after {
    content: "";
    position: absolute;
    transition: transform 0.5s ease;
  }  

  *, *:before, *:after {
    box-sizing: border-box;
  }

.border-effect {
    color: var(--bg-color);
    padding: 10px;
}

.border-effect:before, .border-effect:after {
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-style: solid;
    border-color: var(--bg-color);
}
    
.border-effect:before {
    border-width: 2px 0 2px 0;
    transform:  scaleX(0);
}

.border-effect:after {
    border-width: 0 2px 0 2px;
    transform:  scaleY(0);
}

.border-effect:hover:before, .border-effect:hover:after {
    transform:  scale(1, 1);
}

footer a:hover {
    color: var(--bg-color);
}

.footer-logo {
    font-size: 2rem;
    font-weight: 500;
    margin-bottom: 2rem;
    display: inline-block;
    color: var(--bg-color);
}

.permalinks {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2rem;
    margin: 0 auto 3rem;
}

.footer-socials {
    display: flex;
    justify-content: center;
    gap: 1rem;
    margin-bottom: 4rem;
}

.footer-socials a {
    background: var(--bg-color);
    color: var(--font-color);
    padding: 0.5rem;
    font-size: 1.2rem;
    border-radius: 0.7rem;
    display: flex;
    border: 1px solid transparent;
    transition: var(--transition);
}

.footer-socials a:hover {
    background: transparent;
    color: var(--bg-color);
    border-color: var(--bg-color);
}

.footer-copyright {
    margin-bottom: 4rem;
    color: var(--bg-color);
}

 /********** Media Queries (Small Dev) ************/
 @media screen and (max-width: 600px) {
    .permalinks {
        flex-direction: column;
        gap: 1.5rem;
  }

  .footer-socials {
      margin: 2.6rem;
  }
 }