.container .contact-container {
    width: 58%;
    display: grid;
    grid-template-columns: 30% 58%;
    gap: 12%;
}

.contact-options {
    display: flex;
    flex-direction: column;
    gap: 1.3rem;
}

.contact-option {
    background: var(--bg-variant);
    padding: 1.2rem;
    border-radius: 1.2rem;
    text-align: center;
    border: 1px solid transparent;
    transition: var(--transition);
}

.contact-option:hover {
    background: transparent;
    border-color: var(--color-primary-variant);
}

.contact-icon {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
} 

.contact-option p {
    margin-top: 0.7rem;
    display: inline-block;
    font-size: 0.9rem;
    line-height: 1rem;
    color: var(--font-color);
}

/* Form styling */

form {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
}

input, textarea {
    width: 100%;
    padding: 1.5rem;
    border-radius: 0.5rem;
    background: transparent;
    border: 2px solid var(--color-primary-variant);
    resize: none;
    transition: var(--transition);
    color: var(--font-color);
}

form .btn {
    padding: 1rem;
    font-size: 1.1rem;
}

.success {
    margin-top: -10px;
}

 
/********** Media Queries (Med Dev) ************/
@media screen and (max-width: 1024px) {
   .container.contact-container {
       grid-template-columns: 1fr;
       gap: 2rem;
   }
 }
 
 /********** Media Queries (Small Dev) ************/
 @media screen and (max-width: 600px) {
     .container.contact-container {
         width: var(--container-width-sm);
     }
   
 }