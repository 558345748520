.about-container {
    display: grid;
    grid-template-columns: 35% 50%;
    gap: 15%;
}

.about-me {
    width: 100%;
    aspect-ratio: 1/1;
    border-radius: 2rem;
    background: linear-gradient(
        45deg, 
        transparent,
        var(--color-primary),
        transparent);
        display: grid;
        place-items: center;
}

.about-me-image {
    border-radius: 2rem;
    overflow: hidden;
    transform-origin: rotate(10deg);
    transition: var(--transition);
    max-width: 300px;
}

.about-me-image:hover {
    transform: rotate(15deg);
}

.about-cards {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1.5rem;
}

.about-card {
    background: var(--bg-variant);
    border: 1px solid transparent;
    border-radius: 1rem;
    padding: 2rem;
    text-align: center;
    transition: var(--transition);
}

.about-card:hover {
    background: transparent;
    border-color: var(--color-primary-variant);
    cursor: pointer;
}

.about-icon {
    color: var(--color-primary);
    font-size: 1.4rem;
    margin-bottom: 1rem;
}

.about-card h5 {
    font-size: 0.95rem;
    color: var(--font-color);
}

.about-content p {
    margin: 2rem 0 2.6rem;
    color: var(--alt-font-color);
    font-size: 1.1rem;
}

 
/********** Media Queries (Med Dev) ************/
@media screen and (max-width: 1024px) {
   .about-container {
        grid-template-columns: 1fr;
        gap: 0;
    }
    .about-me {
        width: 50%;
        margin: 2rem auto 4rem;
    }
    .about-content p {
        margin: 1rem 0 1.5rem;
    }
}

/********** Media Queries (Small Dev) ************/
@media screen and (max-width: 600px) {
   .about-me {
       width: 65%;
       margin: 0 auto 3rem;
   }
   .about-cards {
       grid-template-columns: 1fr;
       gap: 1rem;
   }
   .about-content .btn {
       left: 30%;
   }
   .about-content p {
       margin: 1.5rem 0;
   }
   .about-me-image {
    max-width: 197px;
   }
}