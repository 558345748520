.projects-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2.5rem;
}

.projects-item {
    background: var(--bg-variant);
    padding: 1.2rem;
    border-radius: 2rem;
    border: 1px solid transparent;
    transition: var(--transition);
    display: grid;
}

.projects-item:hover {
    border-color: var(--color-primary-variant);
    background: transparent;
    cursor: default;
}

.projects-item-image {
    border-radius: 1.5rem;
    overflow: hidden;
}

.single-image {
    height: 300px;
    float: left;
    object-fit: cover;
}

.projects-item h3 {
    margin: 1.2rem 0 2rem;
    text-align: center;
    color: var(--color-primary);
}

.projects-item-cta {
    display: flex;
    justify-content: center;
    gap: 1rem;
    margin-bottom: 1.2rem;
    line-height: 1.2;
    margin-top: auto;
}

.about {
    margin: 0 auto;
}

.websites-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2.5rem;
}

.desc {
    padding: 5px 5px 10px;
    flex-grow: 1;
}

/********** Media Queries (Med Dev) ************/
@media screen and (max-width: 1024px) {
    .projects-container {
        grid-template-columns: repeat(2, 1fr);
        gap: 1.2rem;
    }
    .websites-container {
        grid-template-columns: repeat(1, 1fr);
        gap: 1.2rem;
    }
 }

 /********** Media Queries (Small Dev) ************/
@media screen and (max-width: 600px) {
   .projects-container {
       grid-template-columns: 1fr;
         gap: 1rem;
   }
 }