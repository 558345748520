nav {
    background: var(--color-tertiary);
    width: max-content;
    padding: 0.7rem 1.7rem;
    z-index: 2;
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    bottom: 2rem;
    display: flex;
    gap: 0.8rem;
    border-radius: 3rem;
    backdrop-filter: blur(15px);
}

nav a {
    background: transparent;
    padding: 0.9rem;
    border-radius: 50%;
    display: flex;
    color: var(--bg-color);
    font-size: 1.1rem;
}

nav a:hover {
    background: rgba(0,0,0,0.3);
    color: var(--bg-color);
}

nav a.active {
    background: var(--color-primary);
    color: var(--bg-color);
}

/********** Media Queries (Med Dev) ************/
@media screen and (max-width: 1024px) {
    nav {
        gap: 1rem;
        padding: 0.5rem 1rem;
    }
    nav a {
        padding: 0.7rem;
  }
 }

 /********** Media Queries (Small Dev) ************/
@media screen and (max-width: 600px) {
   nav {
        justify-content: center;
   }
    nav a {
          padding: 0.4rem;
          font-size: 0.8rem;
    }
 }