.toggle-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
}

.toggle-wrapper span {
    font-size: 32px;
}

.toggle-theme {
    position: relative;
    display: inline-block;
    height: 28px;
    width: 54px;
}

.toggle-theme input {
    display: none;
}

.slider {
    background-color: var(--alt-font-color);
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transition: .2s;
}

.slider:before {
    background-color: var(--font-color);
    content: "";
    height: 28px;
    left: 2px;
    position: absolute;
    transition: .4s;
    width: 28px;
}



input:checked + .slider:before {
    transform: translateX(24px);
}

input:checked + .slider {
    background-color: var(--alt-font-color);
}

.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}

.slider.round:hover {
    background-color: var(--color-primary);
}