header {
    padding-top: 2rem;
    overflow: hidden;
}

.header-container {
    text-align: center;
    height: 100%;
    position: relative;
}

/********** CTA ************/

.cta {
    margin-top: 2.5rem;
    display: flex;
    gap: 1.2rem;
    justify-content: center;
}

/********** Socials ************/

.header-socials {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.2rem;
    position: absolute;
    left: 0;
    bottom: 1rem;
    font-size: 1.3rem;
}

.header-socials:hover {
    cursor: pointer;
    color: var(--color-secondary);
}

.header-socials::after {
    content: '';
    width: 1px;
    height: 2rem;
    background: var(--color-primary);
}
/********** Scroll Down ************/

.scroll-down {
    position: absolute;
    right: -0.1rem;
    bottom: 9rem;
    transform: rotate(90deg);
    text-transform: uppercase;
    font-size: 0.8rem;
    letter-spacing: 2px;
    font-weight: 500;

}
/********** Color Mode ************/
.color-mode {
    display: flex;
    justify-content: center;
    padding-bottom: 1rem;
}

/********** Media Queries (Small Dev) ************/
@media screen and (max-width: 600px) {

    .header-socials, .scroll-down {
        display: none;
    }
}