/* @import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700'); */

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    list-style: none;
    text-decoration: none;
}
:root {
    --bg-color: black;
    --bg-variant: #212529;
    --color-primary: #42d3d1;
    --color-primary-variant: #5c8588;
    --color-secondary: #80f2f4;
    --color-tertiary: #5ec2b7;
    --color-quaternary: #fce181;
    --font-color: #ffffff;
    --alt-font-color: #bdc1c6;
    --color-light: #f8f9fa;
    --transition: all 400ms ease;
    --container-width-lg: 75%;
    --container-width-md: 86%;
    --container-width-sm: 90%;
}

[data-theme="light"] {
    --bg-color: white;
    --bg-variant: #edeef0;
    --alt-font-color: #6b6e70;
    --font-color: #343a40;
    --color-primary: #21807e;
    --color-primary-variant: #2c3e50;
    --color-secondary: #63bbbc;
}

html {
    scroll-behavior: smooth;
}

::-webkit-scrollbar {
    display: none;
}

body {
    background: var(--bg-color);
    color: var(--font-color);
    font-family: 'Roboto', sans-serif;
    line-height: 1.7;
}

/* ********** General Styles ************/

.container {
    width: var(--container-width-lg);
    max-width: var(--container-width-lg);
    margin: 0 auto;
}

h1,
h2,
h3,
h4,
h5 {
    font-weight: 500;
}

h1 {
    font-size: 2.5rem;
}

h2 {
    font-size: 2rem;
}

.sub-title {
    text-transform: uppercase;
    font-size: 1.2rem;
    letter-spacing: 1.5px;
    color: var(--alt-font-color);
    text-align: center;
    margin: 2rem 0;
}

section {
    margin-top: 4rem;
}

section > h2,
section > h5 {
    text-align: center;
    color: var(--alt-font-color);
}

section > h2 {
    color: var(--color-primary);
    margin-bottom: 1rem;
}

.text-light {
    color: var(--alt-font-color);
}


.btn {
    width: max-content;
    display: inline-block;
    color: var(--color-primary);
    border-radius: 0.4rem;
    cursor: pointer;
    border: 1px solid var(--color-primary);
    transition: var(--transition);
    padding: 0.5rem 1rem;
}

.btn:hover {
    background: var(--font-color);
    color: var(--bg-variant);
    border-color: transparent;
}

.btn-primary {
    background: var(--color-primary);
    color: var(--bg-color);
    padding: 0.5rem 1rem;
    border-radius: 0.4rem;
    border: 1px solid var(--color-primary);
    transition: var(--transition);
    cursor: pointer;
}

.btn-primary:hover {
    background: var(--color-tertiary);
    color: var(--font-color);
    border-color: transparent;
}

img {
    display: block;
    width: 100%;
    object-fit: cover;
}

a {
    color: var(--color-primary);
    text-decoration: none;
    transition: var(--transition);
}

a:hover {
    color: var(--color-secondary);
}

.text-light {
    color: var(--font-color);
}

/* ********** Media Queries (Medium Dev) ************/

@media screen and (max-width: 1024px) {
    .container {
        width: var(--container-width-md);
        max-width: var(--container-width-md);
        margin: 0 auto;
    }
    section {
        margin-top: 4rem;
    }
}

/* ********** Media Queries (Small Dev) ************/

@media screen and (max-width: 600px) {
    .container {
        width: var(--container-width-sm);
        max-width: var(--container-width-sm);
    }
    section{
        margin-top: 3rem;
    }
    section > h2 {
        font-size: 1.5rem;
        margin-bottom: 2rem;
    }
}
