.container.container-testimonials {
    width: 60%;
    padding-bottom: 4rem;
}

.client-avatar {
    width: 6rem;
    aspect-ratio: 1/1;
    overflow: hidden;
    border-radius: 50%;
    margin: 0 auto 1rem;
    border: 0.4rem solid var(--color-primary-variant);
}

.testimonial {
    background: var(--bg-variant);
    text-align: center;
    padding: 2rem;
    border-radius: 2rem;
    user-select: none;
}

.client-review {
    color: var(--alt-font-color);
    font-weight: 300;
    font-size: 1.1rem;
    width: 80%;
    display: block;
    margin: 0.8rem auto 0;
}

h5 {
    text-transform: uppercase;
    letter-spacing: 0.8px;
}

.swiper-pagination-clicable, 
.swiper-pagination-bullet {
    background: var(--color-primary) !important;
    width: 10px !important;
    height: 10px !important;
}

 /********** Media Queries (Small Dev) ************/
@media screen and (max-width: 600px) {
   .container.container-testimonials {
         width: var(--container-width-sm);
   }

   .client-review {
       width: var(--container-width-sm);
   }
 }